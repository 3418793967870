<template>
  <v-app class="AppBackgroundDesignVariant1">
    <Snackbar :SnackbarComponent="SnackbarComponent" />
    <v-row class="d-flex align-center justify-center" no-gutters>
      <v-col cols="12" align="center">
        <v-card flat :max-width="VuetifyObj.smAndDown ? GetUpdatedValueMethod(VuetifyObj.width) : 800" height="auto" class="mx-3 my-3 CardVariant1">
          <v-row no-gutters>
            <v-col cols="12" :md="VuetifyObj.mdAndUp ? '6' : '12'" v-if="VuetifyObj.mdAndUp">
              <v-img cover :height="550" src="@/assets/LoginImg.png" />
            </v-col>
            <v-col cols="12" :md="VuetifyObj.mdAndUp ? '6' : '12'">
              <v-row no-gutters>
                <v-col cols="12" class="pt-12">
                  <v-img max-width="150" src="@/assets/logo1_without_bg.png" />
                  <div class="FontFamilyVariant1">Welcome to leap by</div>
                  <div class="FontFamilyVariant1">Quizarre</div>
                  <v-window v-model="StepperValue" :touch="false">
                    <v-window-item :value="1">
                      <v-form class="mx-5 mt-5" ref="EmailValidateForm">
                        <div class="text-left font-weight-bold FontSize16px">Email</div>
                        <v-text-field
                          rounded="lg"
                          variant="outlined"
                          class="textField text-left"
                          density="compact"
                          prepend-inner-icon="mdi-email"
                          v-model="Login.EmailId"
                          :rules="[(v) => !!v || 'Required', (v) => /.+@.+/.test(v) || 'Email must be valid']"
                          @keypress.enter.prevent="ValidateMethod(1)"
                        ></v-text-field>
                      </v-form>
                      <v-card-actions class="mx-5 pa-0 ma-0 mb-6">
                        <v-btn block variant="flat" color="primary" :loading="EmailLoader" class="text-capitalize FontSize16px font-weight-bold" @click="ValidateMethod(1)">Login</v-btn>
                      </v-card-actions>
                    </v-window-item>
                    <v-window-item :value="2">
                      <v-overlay v-model="loaderLoading" opacity="0.8">
                        <v-row align="center" justify="center" class="d-flex justify-center" :style="{ height: `${VuetifyObj.height}px`, width: `${VuetifyObj.width}px` }" no-gutters>
                          <v-col cols="12" align="center" align-self="center">
                            <div class="newLoader"></div>
                            <div class="text-center font-weight-bold text-white mt-8">Please wait..!</div>
                          </v-col>
                        </v-row>
                      </v-overlay>
                      <v-form class="mx-5 my-5" ref="OTPValidateForm">
                        <div class="text-left font-weight-regular FontSize14px">
                          You will receive an OTP at your email address <span class="font-weight-bold">{{ maskedEmail }}</span> for verification.
                        </div>
                        <v-otp-input
                          :length="6"
                          class="OTPField pb-0 mb-0"
                          hide-details
                          v-model="Login.OTP"
                          type="number"
                          :rules="[(v) => !!v || 'required']"
                          @keypress.enter.prevent="ValidateMethod(2)"
                        ></v-otp-input>
                      </v-form>
                      <div class="text-left mx-5 FontSize12px" v-if="counter !== 0">Resend OTP available in {{ counter }}.</div>
                      <div class="text-left mx-5 FontSize12px text-primary font-weight-bold text-decoration-underline cursorPointer" v-if="counter === 0" @click="ValidateMethod(1, 'RESEND_OTP')">
                        Resend OTP
                      </div>
                      <v-card-actions class="mx-5 pa-0 ma-0 mb-6">
                        <v-btn block variant="flat" color="primary" :loading="otpLoader" class="text-capitalize FontSize16px font-weight-bold" @click="ValidateMethod(2)">Verify</v-btn>
                      </v-card-actions>
                    </v-window-item>
                    <v-window-item :value="3">
                      <v-overlay v-model="loaderLoading" opacity="0.8">
                        <v-row align="center" justify="center" class="d-flex justify-center" :style="{ height: `${VuetifyObj.height}px`, width: `${VuetifyObj.width}px` }" no-gutters>
                          <v-col cols="12" align="center" align-self="center">
                            <div class="newLoader"></div>
                            <div class="text-center font-weight-bold text-white mt-8">Please wait..!</div>
                          </v-col>
                        </v-row>
                      </v-overlay>
                      <v-form class="mx-5 my-5" ref="OrgCodeValidateForm">
                        <div class="text-left font-weight-regular FontSize14px">Enter your Organization Code</div>
                        <v-otp-input
                          :length="6"
                          class="OTPField pb-0 mb-0"
                          hide-details
                          type="number"
                          :rules="[(v) => !!v || 'required']"
                          v-model="Login.Org_Code"
                          @keypress.enter.prevent="ValidateMethod(3)"
                        ></v-otp-input>
                      </v-form>
                      <v-card-actions class="mx-5 pa-0 ma-0 mb-6">
                        <v-btn block variant="flat" color="primary" :loading="VerifyCodeLoader" class="text-capitalize FontSize16px font-weight-bold" @click="ValidateMethod(3)">Verify Org Code</v-btn>
                      </v-card-actions>
                      <div class="mt-5 FontSize14px mb-5 text-center mx-8">
                        If you don't have an organization code, please try our
                        <span class="text-decoration-underline font-weight-bold cursorPointer text-primary" @click="ValidateMethod(4)">open quizzes</span>
                      </div>
                    </v-window-item>
                  </v-window>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import { signIn, confirmSignIn, signOut } from "aws-amplify/auth";
import { GetCurrentUser } from "@/mixins/GetCurrentUser.js";
import { switchToATeam } from "@/graphql/mutations.js";
import { generateClient } from "aws-amplify/api";
import { useDisplay } from "vuetify";
import Snackbar from "@/components/Extras/Snackbar.vue";
import axios from "axios";
const client = generateClient();

export default {
  components: {
    Snackbar,
  },
  mixins: [GetCurrentUser],
  data: () => ({
    StepperValue: 1,
    counter: 0,
    interval: null,

    EmailLoader: false,
    loaderLoading: false,
    VerifyCodeLoader: false,
    otpLoader: false,

    SnackbarComponent: {},
    VuetifyObj: {},
    Login: {
      EmailId: "",
      OTP: "",
      Org_Code: "",
    },
  }),
  computed: {
    maskedEmail() {
      if (this.Login.EmailId) {
        const [prefix, domain] = this.Login.EmailId.split("@");
        const maskedPrefix = `${prefix.substring(0, 2)}******`;
        return `${maskedPrefix}@${domain}`;
      }
      return this.Login.EmailId;
    },
  },
  mounted() {
    this.VuetifyObj = useDisplay();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    GetUpdatedValueMethod(currentWidth) {
      if (currentWidth < 400 || currentWidth > 800) {
        return currentWidth - 400;
      } else if (currentWidth > 500 && currentWidth < 700) {
        return currentWidth - 100;
      } else if (currentWidth > 700 && currentWidth < 800) {
        return currentWidth - 300;
      } else if (currentWidth > 400 && currentWidth < 500) {
        return currentWidth - 50;
      } else if (currentWidth === 800) {
        return currentWidth - 250;
      } else {
        return currentWidth;
      }
    },
    async ValidateMethod(Step, action) {
      switch (Step) {
        case 1:
          try {
            const { valid } = await this.$refs.EmailValidateForm.validate();
            if (valid) {
              if (action === "RESEND_OTP") {
                this.loaderLoading = true;
              }
              this.EmailLoader = true;
              this.user = await signIn({
                username: this.Login.EmailId,
                options: {
                  authFlowType: "CUSTOM_WITHOUT_SRP",
                },
              });
              if (this.user.nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE") {
                this.SnackbarComponent = {
                  SnackbarVmodel: true,
                  SnackbarColor: "greenColorVariant2",
                  SnackbarText: `Verification OTP sent to ${this.Login.EmailId}`,
                };
                this.EmailLoader = false;
                this.loaderLoading = false;
                if (this.StepperValue !== 2) {
                  this.StepperValue = 2;
                }
                this.counter = 30;
                this.startCounter();
              }
            } else {
              this.EmailLoader = false;
              this.SnackbarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "redColorVariant3",
                SnackbarText: "Please Enter Email ID to proceed.!",
              };
            }
          } catch (error) {
            console.log("Error Mesage",error.message)
            if (error.message === "There is already a signed in user.") {
              await signOut({ global: true });
              this.ValidateMethod(1);
            } else if (error.message === "DefineAuthChallenge failed with error Error: NOT_AUTHORIZED :User Does Not Exist!!." || error.message === 'User does not exist.') {
              
              this.ValidateMethod(5);
            } else {
              this.EmailLoader = false;
              this.SnackbarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "redColorVariant3",
                SnackbarText: error.message,
              };
            }
          }
          break;
        case 2:
          try {
            const { valid } = await this.$refs.OTPValidateForm.validate();
            if (valid && this.Login.OTP !== "") {
              this.otpLoader = true;
              await confirmSignIn({ challengeResponse: this.Login.OTP })
                .then(() => {
                  // this.SnackbarComponent = {
                  //   SnackbarVmodel: true,
                  //   SnackbarColor: "greenColorVariant2",
                  //   SnackbarText: "Login Successfull",
                  // };
                  this.otpLoader = false;
                  sessionStorage.setItem("loggedin_user", this.Login.EmailId);
                  this.ActivateMethod();
                })
                .catch((err) => {
                  if (err.message === "VerifyAuthChallengeResponse failed with error Incorrect OTP!!.") {
                    this.SnackbarComponent = {
                      SnackbarVmodel: true,
                      SnackbarColor: "redColorVariant3",
                      SnackbarText: "Incorrect OTP",
                    };
                    this.counter = 0;
                  } else if (err.message === "Invalid session for the user.") {
                    this.SnackbarComponent = {
                      SnackbarVmodel: true,
                      SnackbarColor: "redColorVariant3",
                      SnackbarText: "OTP Expired",
                    };
                    this.counter = 0;
                  } else {
                    this.SnackbarComponent = {
                      SnackbarVmodel: true,
                      SnackbarColor: "redColorVariant3",
                      SnackbarText: err.message,
                    };
                  }
                  this.otpLoader = false;
                });
            } else {
              this.SnackbarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "redColorVariant3",
                SnackbarText: "Please Enter OTP to proceed.!",
              };
            }
          } catch (error) {
            this.otpLoader = false;
            console.log("Error", error);
          }
          break;
        case 3:
          try {
            this.VerifyCodeLoader = true;
            let mutation_name = switchToATeam;
            let mutation_result = "switchToATeam";
            let result = await client.graphql({
              query: mutation_name,
              variables: {
                input: {
                  team_code: this.Login.Org_Code,
                  team_is_default: "FALSE",
                },
              },
            });
            let ResultObj = JSON.parse(result.data[mutation_result]);
            if (ResultObj.status == "SUCCESS") {
              this.SnackbarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "greenColorVariant2",
                SnackbarText: ResultObj.status_message,
              };

              this.ActivateMethod();
            }
            this.VerifyCodeLoader = false;
          } catch (error) {
            this.VerifyCodeLoader = false;
            this.SnackbarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "redColorVariant3",
              SnackbarText: error.errors[0].message,
            };
            this.Login.team_code = "";
            console.log("Err", error);
          }
          break;
        case 4:
          try {
            this.loaderLoading = true;
            let mutation_name = switchToATeam;
            let mutation_result = "switchToATeam";
            let result = await client.graphql({
              query: mutation_name,
              variables: {
                input: {
                  team_is_default: "TRUE",
                },
              },
            });
            let ResultObj = JSON.parse(result.data[mutation_result]);
            if (ResultObj.status == "SUCCESS") {
              this.SnackbarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "greenColorVariant2",
                SnackbarText: ResultObj.status_message,
              };

              this.ActivateMethod();
            }
          } catch (error) {
            this.SnackbarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "redColorVariant3",
              SnackbarText: error.errors[0].message,
            };
            this.loaderLoading = false;
            this.Login.team_code = "";
            console.log("Err", error);
          }
          break;
        case 5:
          try {
            this.EmailLoader = true;
            let data = {
              command: "signupUser",
              user_email_id: `${this.Login.EmailId}`,
            };
            let config = {
              method: "post",
              headers: {},
              url: "https://2olarspf81.execute-api.ap-south-1.amazonaws.com/production/signup_user",
              data: data,
            };
            let result = await axios(config);
            if (result.data.status === "SUCCESS") {
              this.ValidateMethod(1);
            }
          } catch (error) {
            this.EmailLoader = false;
            this.SnackbarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "redColorVariant3",
              SnackbarText: error,
            };
          }
          break;
      }
    },
    async ActivateMethod() {
      this.loaderLoading = true;
      let current_logged_in_user_result = await this.GetCurrentUserMethod();
      this.loaderLoading = false;
      if (current_logged_in_user_result.status === "SUCCESS") {
        if (current_logged_in_user_result.is_team_switch_required) {
          this.StepperValue = 3;
        } else {
          this.$router.push("LandingPage");
        }
      } else if (current_logged_in_user_result.status === "ERROR") {
        this.SnackbarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "redColorVariant3",
          SnackbarText: current_logged_in_user_result.message.errors[0].message,
        };
        this.Login.OTP = "";
        this.$refs.OTPValidateForm.reset();
        this.StepperValue = 1;
      }
    },
    startCounter() {
      this.interval = setInterval(() => {
        if (this.counter > 0) {
          this.counter--;
        } else {
          clearInterval(this.interval);
        }
      }, 1000);
    },
  },
};
</script>

<style></style>
