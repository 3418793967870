/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const switchToATeam = /* GraphQL */ `
  mutation SwitchToATeam($input: switchToATeamInput) {
    switchToATeam(input: $input)
  }
`;
export const createQuizAttempts = /* GraphQL */ `
  mutation CreateQuizAttempts($input: createQuizAttemptsInput) {
    createQuizAttempts(input: $input)
  }
`;
export const endQuizAttempt = /* GraphQL */ `
  mutation EndQuizAttempt($input: endQuizAttemptInput) {
    endQuizAttempt(input: $input)
  }
`;
export const submitQuizQuestionAnswer = /* GraphQL */ `
  mutation SubmitQuizQuestionAnswer($input: submitQuizQuestionAnswerInput) {
    submitQuizQuestionAnswer(input: $input)
  }
`;
export const updateProfile = /* GraphQL */ `
  mutation UpdateProfile($input: updateProfileInput) {
    updateProfile(input: $input)
  }
`;
export const generateS3UploadURL = /* GraphQL */ `
  mutation GenerateS3UploadURL($input: commons3Input) {
    generateS3UploadURL(input: $input)
  }
`;
