<template>
  <v-app class="AppBackgroundDesignVariant1">
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=geist");

/* ::-webkit-scrollbar {
  display: none;
}
* {
  -ms-overflow-style: none;
  scrollbar-width: none;
} */
.AppBackgroundDesignVariant1 {
  /* background: linear-gradient(125deg, #24156d, #008cda) !important; */
  background-image: url('@/assets/bg9.svg')  !important;
  background-repeat: no-repeat !important;
  background-size: cover !important; 
  height: 100% !important;
  width: 100vw !important;
}

.CardVariant1 {
  box-shadow: 15px 12px 20px #acd7ee7f !important;
}
.FontSize12px {
  font-size: 12px !important;
  font-family: "Geist", "Nunito Sans" !important;
}
.FontSize13px {
  font-size: 13px !important;
  font-family: "Geist", "Nunito Sans" !important;
}
.FontSize14px {
  font-size: 14px !important;
  font-family: "Geist", "Nunito Sans" !important;
}
.FontSize16px {
  font-size: 16px !important;
  font-family: "Geist", "Nunito Sans" !important;
}
.FontSize18px {
  font-size: 18px !important;
  font-family: "Geist", "Nunito Sans" !important;
}
.FontSize20px {
  font-size: 20px !important;
  font-family: "Geist", "Nunito Sans" !important;
}
.FontSize22px {
  font-size: 22px !important;
  font-family: "Geist", "Nunito Sans" !important;
}
.FontSize24px {
  font-size: 24px !important;
  font-family: "Geist", "Nunito Sans" !important;
}
.FontSize26px {
  font-size: 26px !important;
  font-family: "Geist", "Nunito Sans" !important;
}
.FontSize28px {
  font-size: 28px !important;
  font-family: "Geist", "Nunito Sans" !important;
}
.FontSize30px {
  font-size: 30px !important;
  font-family: "Geist", "Nunito Sans" !important;
}
.FontSize40px {
  font-size: 40px !important;
  font-family: "Geist", "Nunito Sans" !important;
}
.FontSize50px {
  font-size: 50px !important;
  font-family: "Geist", "Nunito Sans" !important;
}
.FontFamilyVariant1 {
  font-family: "Geist", "Nunito Sans" !important;
  font-size: 25px !important;
  font-weight: 600 !important;
}
.FontFamilyVariant2 {
  font-family: Gabriola, Times, serif !important;
}
.FontFamilyVariant3 {
  font-family: "Times New Roman", Times, serif !important;
}
.WidthVariant1 {
  width: 400px !important;
}
.WidthVariant2 {
  max-width: 200px !important;
}
.dialogStickyClass {
  align-self: flex-start !important;
}
.textField.v-text-field input {
  min-height: 12px !important;
  display: flex !important;
  align-items: center !important;
  /* padding-bottom: 5px !important; */
}
.dropdownField .v-input__control {
  height: 35px !important;
}
.dropdownFieldIcon .v-field__append-inner {
  padding-bottom: 10px !important;
}
.tableClassVariant th {
  height: 32px !important;
  font-size: 13px !important;
  background: linear-gradient(to bottom, #d8d8d8, #e5e9ee) !important;
  color: #000 !important;
}
.tableClassVariant td {
  background-color: #ffffff00 !important;
  height: 32px !important;
  font-size: 12px !important;
}

.tableClassVariant .v-data-table__tr {
  background: rgba(0, 0, 0, 0) !important;
}
.textFieldbg.v-text-field .v-field {
  background: #fac62a !important;
  color: #000 !important;
}
.OTPField .v-otp-input__content {
  padding: 0 !important;
  max-width: 500px !important;
}
.v-field__input {
  font-size: 12px !important;
}
.textArea .v-field__input {
  font-size: 20px !important;
  text-align: center !important;
  justify-content: center !important;
  justify-items: center !important;
  height: 200px !important;
  margin-top: 10% !important;
  padding: 40px !important;
  overflow: hidden !important;
  overflow-wrap: break-word !important;
  white-space: pre-wrap !important;
  word-break: break-word !important;
}
.paddingVariant1 {
  padding: 130px !important;
}
.paddingVariant2 {
  padding: 65px !important;
}
.CursorVariant {
  cursor: pointer !important;
}
.CrtansBackground {
  /* background-color: green !important; */
  width: auto !important;
  margin-left: 12px !important;
  border-radius: 20px !important;
  min-height: 30px !important;
  color: green !important;
}
.WrngAnsBackground {
  /* background-color: red !important; */
  width: auto !important;
  min-height: 30px !important;
  margin-left: 12px !important;
  border-radius: 20px !important;
  color: red !important;
}
.hide-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.hide-scrollbar ::-webkit-scrollbar {
  display: none;
}
.cursorPointer {
  cursor: pointer !important;
}
.loader {
  width: 50px;
  margin-top: 700% !important;
  margin-left: 1400% !important;
  aspect-ratio: 1;
  display: grid;
  border: 4px solid #0000;
  border-radius: 50%;
  border-color: #ccc #0000;
  animation: l16 1s infinite linear;
}
.loader::before,
.loader::after {
  content: "";
  grid-area: 1/1;
  margin: 2px;
  border: inherit;
  border-radius: 50%;
}
.loader::before {
  border-color: #24156d #0000;
  animation: inherit;
  animation-duration: 0.5s;
  animation-direction: reverse;
}
.loader::after {
  margin: 8px;
}
@keyframes l16 {
  100% {
    transform: rotate(1turn);
  }
}
.newLoader {
  width: 48px;
  height: 48px;
  margin: auto;
  position: relative;
}
.newLoader:before {
  content: "";
  width: 48px;
  height: 5px;
  background-image: linear-gradient(90deg, #6cfacd72, #008ada72, #281a6b5f);
  opacity: 0.25;
  position: absolute;
  top: 60px;
  left: 0;
  border-radius: 50%;
  animation: shadow 0.5s linear infinite;
}
.newLoader:after {
  content: "";
  width: 100%;
  height: 100%;
  background-image: linear-gradient(90deg, #6cfacd, #008bda, #271a6b);
  animation: bxSpin 0.5s linear infinite;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
}
@keyframes bxSpin {
  17% {
    border-bottom-right-radius: 3px;
  }
  25% {
    transform: translateY(9px) rotate(22.5deg);
  }
  50% {
    transform: translateY(18px) scale(1, 0.9) rotate(45deg);
    border-bottom-right-radius: 40px;
  }
  75% {
    transform: translateY(9px) rotate(67.5deg);
  }
  100% {
    transform: translateY(0) rotate(90deg);
  }
}

@keyframes shadow {
  0%,
  100% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.2, 1);
  }
}
</style>

